export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || 'localhost:3000';

export const wikiBaseUrl = process.env.NEXT_PUBLIC_WIKI_BASE_URL || 'http://localhost:3000';

export const CookieRefExpireTime = 60 * 60 * 24 * 30 * 3; // 90 days

export const FacebookAppID = '561663595976950';

export const defaultLocale = 'en';

export const ignoredWritesRoutes = ['on-chain-report'];

export enum SUBSCRIPTION_PLAN {
	TRIAL = 12,
	PRO = 13,
	ALPHA = 14,
}

export const PACKAGE_PLANS = [SUBSCRIPTION_PLAN.ALPHA, SUBSCRIPTION_PLAN.PRO, SUBSCRIPTION_PLAN.TRIAL];

// plans's order is important(vip -> normal)
export const PREMIUM_PACKAGE_PLANS = [SUBSCRIPTION_PLAN.ALPHA, SUBSCRIPTION_PLAN.PRO];

export const SUPPORTED_REDIRECT = [
	{
		id: 'dashboard',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/dashboard`,
	},
	{
		id: 'dashboard-trial',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/api/upgrade-plan`,
	},
	{
		id: 'dashboard-registration',
		url: 'https://docs.google.com/forms/d/e/1FAIpQLSfRisUnL4cmVcm2GJy5epzXosK2tkVDI9a4WKtRWSQoSc7lag/viewform',
	},
	{
		id: 'switch-plan-m1',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/switch-plans?price=M1`,
	},
	{
		id: 'switch-plan-m3',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/switch-plans?price=M3`,
	},
	{
		id: 'switch-plan-m6',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/switch-plans?price=M6`,
	},
	{
		id: 'switch-plan-m12',
		url: `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/switch-plans?price=M12`,
	},
];

export enum REFERRAL_CAMPAIGN {
	SIGN_UP = 1,
}

export const REFERRAL_SEARCH_PARAM = 'ref';

export enum REPORT_PLAN {
	PRO = 8,
	ALPHA = 15,
}

export const REPORT_PLANS = [REPORT_PLAN.ALPHA, REPORT_PLAN.PRO];
