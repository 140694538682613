import { AppProvider } from '@/context/appProvider';
import { RouterScrollProvider } from '@moxy/next-router-scroll';
import { Analytics } from '@vercel/analytics/react';
import dayjs from 'dayjs';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import nextI18nextConfig from '../../next-i18next.config';

import Script from 'next/script';
import 'nprogress/nprogress.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/global.css';
require('dayjs/locale/vi');

const MyApp = ({ Component, pageProps }: AppProps) => {
	const router = useRouter();

	NProgress.configure({ showSpinner: false });

	const { initialLocale } = pageProps?._nextI18Next || {};
	if (initialLocale) {
		dayjs.locale(initialLocale);
	}

	useEffect(() => {
		const handleRouteStart = () => NProgress.start();
		const handleRouteDone = (e) => {
			NProgress.done();
		};

		router.events.on('routeChangeStart', handleRouteStart);
		router.events.on('routeChangeComplete', handleRouteDone);
		router.events.on('routeChangeError', handleRouteDone);

		return () => {
			// Make sure to remove the event handler on unmount!
			router.events.off('routeChangeStart', handleRouteStart);
			router.events.off('routeChangeComplete', handleRouteDone);
			router.events.off('routeChangeError', handleRouteDone);
		};
	}, [router.events]);

	return (
		<>
			{process.env.NEXT_PUBLIC_GTM_KEY ? (
				<Script
					id="gtm-script"
					strategy="worker"
					dangerouslySetInnerHTML={{
						__html: `
                (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-${process.env.NEXT_PUBLIC_GTM_KEY}');
              `,
					}}
				/>
			) : null}
			{process.env.NEXT_PUBLIC_ENABLE_ERUDA === 'true' ? (
				<>
					<Script src="//cdn.jsdelivr.net/npm/eruda" />
					<Script id="eruda-init" strategy="worker" dangerouslySetInnerHTML={{ __html: 'eruda.init();' }} />
				</>
			) : null}

			<RouterScrollProvider>
				<ThemeProvider attribute="class" defaultTheme="dark">
					<SessionProvider>
						<AppProvider>
							<ToastContainer />
							<Component {...pageProps} />
						</AppProvider>
					</SessionProvider>
				</ThemeProvider>
			</RouterScrollProvider>

			{process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && <Analytics />}
		</>
	);
};

export default appWithTranslation(MyApp, nextI18nextConfig);
