import Cookies from 'js-cookie';

type TokenName = 'access-token' | 'refresh_token' | 'id_token' | 'locale';

export const TokenExpiredTime = 60 * 60 * 24 * 30 * 6; // 6 months

/**
 * Save token to Cookies
 *
 * @param name Token name
 * @param value Token value
 * @param expiresIn Token expires in (seconds)
 */
export const saveToken = (name: TokenName, value: string, expiresIn: number): string | undefined => {
  return Cookies.set(name, value, {
    expires: new Date(new Date().getTime() + expiresIn * 1000),
  });
};

/**
 * Get token from Cookies
 *
 * @param name Token name
 */
export const getToken = (name: TokenName): string | undefined => {
  return Cookies.get(name);
};

/**
 * Remove all cookies
 */
export const clearCookies = (): void => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    // Here you pass the same attributes that were used when the cookie was created
    // and are required when removing the cookie
    const neededAttributes = {};
    Cookies.remove(cookieName, neededAttributes);
  });
};

/**
 * Save item to Cookies
 *
 * @param name Item name
 * @param value Item value
 * @param expiresIn Token expires in (seconds)
 */
type ItemName = 'ref' | 'partner'
export const setItem = (name: ItemName, value: string, expiresIn: number): string | undefined => {
  return Cookies.set(name, value, {
    expires: new Date(new Date().getTime() + expiresIn * 1000),
  });
};

/**
 * Get item from Cookies
 *
 * @param name Item name
 */
export const getItem = (name: ItemName): string | undefined => {
  return Cookies.get(name);
};