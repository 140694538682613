const LocizeBackend = require('i18next-locize-backend/cjs')
const ChainedBackend= require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

const defaultLocale = 'en';
const debugI18n = ['true', 1].includes(
  process?.env?.NEXTJS_DEBUG_I18N ?? 'false'
);
const isBrowser = typeof window !== 'undefined'

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: 'default',
    locales: ['default', 'vi', 'en'],
    localeDetection: false,
  },
  trailingSlash: true,
  saveMissing: false,
  strictMode: true,
  serializeConfig: false,
  reloadOnPrerender: process?.env?.NODE_ENV === 'development',
  react: {
    useSuspense: false,
  },
  debug: debugI18n,
  // preload: true,
  backend: {
    backendOptions: [{
      expirationTime: 30 * 60 * 1000 // 30mins
    }, {
      projectId: 'd3b405cf-2532-46ae-adb8-99e88d8767301',
      version: 'latest'
    }],
    backends: isBrowser ? [LocalStorageBackend, LocizeBackend] : [],
  },
  use: isBrowser ? [ChainedBackend/*, require('locize').locizePlugin*/] : [],
  /*
  interpolation: {
    escapeValue: false,
  },
  */
  ns: ['common', 'system', 'banner', 'about', 'campaign'],
  defaultNS: 'common',
  debug: false, // process.env.NODE_ENV === 'development',
  /** To avoid issues when deploying to some paas (vercel...) */
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/locales',
};
