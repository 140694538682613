/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import { createContext, useContext, useEffect, useState } from 'react';

import { clearCookies, getToken, setItem } from '@/lib/cookies';
import type { User } from '@/types/user';
import { useRouter } from 'next/router';
import { CookieRefExpireTime } from '@/lib/constants';
import { useSession } from 'next-auth/react';

export type AppContextType = {
	isLoadingUser: boolean;
	setIsLoadingUser: (val: boolean) => void;

	user?: User;
	setUser: (user: User) => void;

	refetch?: any;

	connectWallet: (c: any) => Promise<void>;
	walletAddress?: string;
	setWalletAddress: (add: string) => void;

	refetchUserProfile?: () => void;
};

export const AppContext = createContext<AppContextType>({
	isLoadingUser: false,
	setIsLoadingUser: () => {},

	user: undefined,
	setUser: () => {},

	connectWallet: async (c: any) => {},
	walletAddress: undefined,
	setWalletAddress: () => {},

	refetchUserProfile: () => {},

	refetch: () => {},
});

function isMobileDevice() {
	return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(onConnected) {
	if (!(window as any).ethereum) {
		alert('Get MetaMask!');
		return;
	}

	const accounts = await (window as any).ethereum.request({
		method: 'eth_requestAccounts',
	});

	onConnected(accounts[0]);
}

async function checkIfWalletIsConnected(onConnected) {
	if ((window as any).ethereum) {
		const accounts = await (window as any).ethereum.request({
			method: 'eth_accounts',
		});

		if (accounts.length > 0) {
			const account = accounts[0];
			onConnected(account);
			return;
		}

		if (isMobileDevice()) {
			await connect(onConnected);
		}
	}
}

export function AppProvider({ children }) {
	const router = useRouter();
	const [userProfile, setUserProfile] = useState<User>();
	const [isLoadingUser, setIsLoadingUser] = useState(false);
	const [walletAddress, setWalletAddress] = useState<string>();
	const { data: session } = useSession();

	useEffect(() => {
		if (session && session.user) {
			setUserProfile(session.user as any);
		}
	}, [session]);

	const loadUserProfile = async () => {
		if (isLoadingUser) return;

		setIsLoadingUser(true);

		// try {
		// 	const { me, error } = await (await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/user/profile`)).json();

		// 	if (error) {
		// 		// clearCookies();
		// 	} else {
		// 		setUserProfile({ ...me });
		// 	}
		// } catch (e) {
		// 	console.log('🚀 ~ file: appProvider.tsx:96 ~ loadUserProfile ~ e', e);
		// 	setUserProfile(undefined);
		// }

		// const token = getToken('access_token');
		// if (token) {
		// 	setIsLoadingUser(true);

		// 	try {
		// 		const { me, error } = await (await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/user/profile`)).json();

		// 		if (error) {
		// 			clearCookies();
		// 		} else {
		// 			setUserProfile({ ...me });
		// 		}
		// 	} catch (e) {
		// 		console.log('🚀 ~ file: appProvider.tsx:96 ~ loadUserProfile ~ e', e);
		// 	}
		// } else {
		// 	setUserProfile(undefined);
		// }

		setIsLoadingUser(false);
	};

	const saveReferralCode = () => {
		const { ref, partner } = router.query;
		if (ref) {
			setItem('ref', ref as string, CookieRefExpireTime);
		}

		if (partner) {
			setItem('partner', partner as string, CookieRefExpireTime);
		}
	};

	// useEffect(() => {
	// 	loadUserProfile();
	// 	return () => {};
	// }, []);

	useEffect(() => {
		if (router.isReady) {
			saveReferralCode();
		}
	}, [router.isReady]);

	return (
		<AppContext.Provider
			value={{
				user: userProfile,
				setUser: (u: User) => setUserProfile(u),
				isLoadingUser,
				setIsLoadingUser,
				connectWallet: connect,
				walletAddress,
				setWalletAddress,
				refetchUserProfile: loadUserProfile,
				refetch: () => {
					console.log('refetch');
					loadUserProfile();
				},
			}}
		>
			{children}
		</AppContext.Provider>
	);
}

export function useAppContext() {
	const context = useContext(AppContext);

	if (!context) {
		console.error('Error deploying App Context!!!');
	}

	return context;
}
